import Vue from 'vue'
import VueRouter from 'vue-router'
import { auth } from '@/services/firebase'
import i18n from '@/plugins/i18n'
import userRoutes from '@/modules/user/router'
import superadminRoutes from '@/modules/superadmin/router'
import organizationRoutes from '@/modules/organization/router'
import projectRoutes from '@/modules/project/router'
import streamingRoutes from '@/modules/streaming/router'
import clubRoutes from '@/modules/club/router'
import clubEnrollmentFormRoutes from '@/modules/clubEnrollmentForm/router'
import dynamicLinkRoutes from '@/dynamicLinkRouter'
import clusterRoutes from '@/modules/cluster/router'
import vendorRoutes from '@/modules/vendors/routes/vendorRouter'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: { name: 'redirectAfterLogin' },
  },
  ...userRoutes,
  ...superadminRoutes,
  ...organizationRoutes,
  ...projectRoutes,
  ...streamingRoutes,
  ...clubRoutes,
  ...dynamicLinkRoutes,
  ...clubEnrollmentFormRoutes,
  ...clusterRoutes,
  ...vendorRoutes,
  {
    path: '/status/:type/:title/:body?',
    name: 'status',
    component: () => import('@/components/Status'),
    meta: {
      title: () => `${i18n.t(`common.${this.$route.params.type}`)} - OWQLO`,
    },
    props: true,
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/components/404'),
    meta: {
      title: () => `404 - ${i18n.t('404.errorDescription')}`,
    },
  },
  { path: '*', redirect: { name: '404' }, hidden: true },
  {
    path: '/403',
    name: '403',
    component: () => import('@/components/403'),
    meta: {
      title: () => `403 - ${i18n.t('403.errorDescription')}`,
    },
  },
  { path: '*', redirect: { name: '403' }, hidden: true },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  from.meta.scrollYPosition = window.scrollY
  const isPublicRoute = ['status', 'set_password', 'verify_email', 'login', 'login-club', 'register', 'streaming', 'dynamicLink', 'audienceStreaming', 'organization-privacy-policy'].includes(to.name)
  || to.name.startsWith('club-enrollment-form')
  if (!auth.currentUser && !isPublicRoute) next({ name: 'login', params: { redirectTo: to } })
  else next()
})

router.afterEach(to => {
  document.title = to.meta.title ? to.meta.title() : 'Admin'
})

export default router
