import rolesEnum from '@/enums/rolesEnum'
import sportsEnum from '@/enums/sportsEnum'
import store from '@/store'

export default () => {
  const routerGuardShowCoupons = store.getters['organization/routerGuardShowCoupons']
  const organization = store.getters['organization/data']
  return [
    {
      id: 'settings',
      name: 'organization.sections.settings',
      icon: 'settings',
      show: true,
    },
    {
      id: 'globalSettings',
      name: 'organization.sections.globalSettings',
      route: { name: 'organization-settings' },
      parentId: 'settings',
      show: store.getters['organization/userRole'] === rolesEnum.ADMIN,
    },
    {
      id: 'terms',
      name: 'organization.sections.terms',
      route: { name: 'organization-terms' },
      parentId: 'settings',
      show: store.getters['organization/userRole'] === rolesEnum.ADMIN,
    },
    {
      id: 'projects',
      name: 'organization.sections.projects',
      icon: 'folder_open',
      route: { name: 'organization-projects' },
      show: true,
    },
    {
      id: 'tickets',
      name: 'organization.sections.tickets',
      icon: 'confirmation_number',
      route: { name: 'organization-tickets' },
      show: [sportsEnum.GUILD].includes(organization.sport) && store.getters['organization/userRole'] === rolesEnum.ADMIN,
    },
    {
      id: 'auTrips',
      name: 'cluster.sections.auTrips',
      icon: 'directions_bus',
      route: { name: 'organization-auTrips' },
      show: [sportsEnum.GUILD].includes(organization.sport) && store.getters['organization/userRole'] === rolesEnum.LALIGADMIN,
    },
    {
      id: 'benefitsRequests',
      name: 'cluster.sections.benefitRequests',
      icon: 'paid',
      route: { name: 'organization-benefits-requests' },
      show: [sportsEnum.GUILD].includes(organization.sport) && store.getters['organization/userRole'] === rolesEnum.LALIGADMIN,
    },
    ...(window.isDev ? [{
      id: 'accomodations',
      name: 'organization.sections.accomodations',
      icon: 'hotel',
      route: { name: 'organization-accomodations' },
      parentId: 'travels',
      show: organization.features?.accomodationForm && store.getters['organization/userRole'] === rolesEnum.ADMIN,
    }] : []),
    {
      id: 'users',
      name: 'project.sections.users',
      icon: 'person_outline',
      show: true,
    },
    {
      id: 'subscribers',
      name: 'project.sections.players-suscribers',
      route: { name: 'organization-subscribers' },
      parentId: 'users',
      show: store.getters['organization/userRole'] === rolesEnum.ADMIN,
    },
    {
      id: 'peopleAdmins',
      name: 'organization.sections.peopleAdmins',
      route: { name: 'organization-staff-list', params: { typeId: 'admin' } },
      parentId: 'users',
      show: store.getters['organization/userRole'] === rolesEnum.ADMIN,
    },
    {
      id: 'payments',
      name: 'project.sections.payments',
      icon: 'credit_card',
      show: true,
    },
    {
      id: 'vendors',
      name: 'organization.sections.vendors',
      route: { name: 'organization-vendors' },
      parentId: 'payments',
      show: store.getters['organization/userRole'] === rolesEnum.ADMIN,
    },
    {
      id: 'paymentsHistory',
      name: 'common.history',
      route: { name: 'organization-payments-history' },
      parentId: 'payments',
      show: store.getters['organization/userRole'] === rolesEnum.ADMIN,
    },
    {
      id: 'coupons',
      name: 'project.sections.coupons',
      route: { name: 'organization-coupons' },
      parentId: 'payments',
      show: store.getters['organization/userRole'] === rolesEnum.ADMIN && routerGuardShowCoupons,
    },
    /* {
    id: 'stats',
    name: 'organization.sections.stats',
    icon: 'insert_chart_outlined',
    route: { name: 'organization-statistics' },
    show: store.getters['organization/userRole'] === rolesEnum.ADMIN,
  }, */
    {
      id: 'news',
      name: 'project.sections.posts',
      icon: 'wysiwyg',
      route: { name: 'organization-posts' },
      show: store.getters['organization/userRole'] === rolesEnum.ADMIN,
    },
    {
      id: 'pushNotifications',
      name: 'organization.sections.pushNotifications',
      icon: 'add_alert',
      route: { name: 'organization-pushNotifications' },
      show: store.getters['organization/userRole'] === rolesEnum.ADMIN,
    },
    {
      id: 'eventLog',
      name: 'organization.sections.eventLog',
      icon: 'schedule',
      route: { name: 'organization-eventLog' },
      show: store.getters['organization/userRole'] === rolesEnum.ADMIN,
    },
    {
      id: 'products',
      name: 'common.products',
      icon: 'shopping_cart',
      show: true,
    },
    {
      id: 'productsConfiguration',
      name: 'organization.sections.settings',
      route: { name: 'organization-products' },
      parentId: 'products',
      show: store.getters['organization/userRole'] === rolesEnum.ADMIN,
    },
    {
      id: 'orders',
      name: 'organization.sections.orders',
      route: { name: 'organization-list-orders' },
      parentId: 'products',
      show: store.getters['organization/userRole'] === rolesEnum.ADMIN,
    },
    {
      id: 'divider-3',
      type: 'divider',
      isStyle: true,
      show: true,
    },
  ]
}
