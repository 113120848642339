import { firestoreAction } from 'vuexfire'
import i18n from '@/plugins/i18n'
import { db, FieldValue, uploadFileAndGetUrl, callBackend } from '@/services/firebase'
import logEvent from '@/utils/logEvent'
import getEmailLoginLink from '@/utils/getEmailLoginLink'
import getOrganizationCurrentRoleFromBooleans from '@/enums/utils/getOrganizationCurrentRoleFromBooleans'
import { subscribeToEntity, unsubscribeFromEntity } from '@/utils/entitySubscriptions'
import organizationAu from '@/enums/organizationAu'
import { getOrganizationApp } from '@/utils/appUtils'
import organizationISL from '@/enums/organizationISL'

export default {
  namespaced: true,
  state: () => ({
    dbData: null,
    dbSport: null,
    dbEventLog: null,
    dbSubscribers: [],
    dbClusters: [],
    dbProjects: [],
    dbBenefitRequests: [],
    dbAdmins: [],
    dblaligaAdmins: [],
    dbLaLigaTeams: [],
    dbCollaborators: [],
    dbTerms: [],
    dbBuys: [],
    dbCoupons: [],
    dbProducts: [],
    dbAuTrips: [],
    dbRolePresets: [],
    routerGuardShowCoupons: false,
  }),

  getters: {
    isUserAdmin: (state, getters, rootState, rootGetters) => rootGetters['user/adminOrganizationIds'].includes(state.dbData?.id) || rootGetters['superadmin/isUserSuperadmin'] || getters.islaligaAdmin,
    islaligaAdmin: (state, getters, rootState, rootGetters) => rootGetters['user/laligaAdminOrganizationIds'].includes(state.dbData?.id) || rootGetters['superadmin/isUserSuperadmin'],
    isSoccer: state => state.dbData.sport === 'soccer',
    isBasketball: state => state.dbData.sport === 'basketball',
    isGuild: state => state.dbData.sport === 'guild',
    isOrganizationAu: state => state.dbData.id === organizationAu.organizationId,
    isOrganizationISL: state => state.dbData.id === organizationISL.organizationId,
    organizationName: state => state.dbData.name,
    isUserCollaborator: (state, getters, rootState, rootGetters) => rootGetters['user/collabOrganizationIds'].includes(state.dbData?.id) || getters.isUserAdmin,
    rawEvents: state => state.dbEventLog?.events ?? [],
    projects: (state, getters, rootState) => state.dbProjects.map(project => ({
      ...project,
      get status() {
        if (!this.active) return 'archived'
        if (!this.published) return 'unpublished'
        return 'upToDate'
      },
      isFavorite: rootState.user.dbData?.favorites?.projects?.includes(project.id),
    })),
    activeProjects: state => state.dbProjects.filter(project => project.active && project.published).filter(Boolean),
    benefitRequests: state => state.dbBenefitRequests,
    clusters: (state, getters, rootState) => state.dbClusters.map(cluster => ({
      ...cluster,
      get status() {
        if (!this.active) return 'archived'
        if (!this.published) return 'unpublished'
        return 'upToDate'
      },
      isFavorite: rootState.user.dbData?.favorites?.clusters?.includes(cluster.id),
    })),
    members: state => [...new Set([
      ...state.dbAdmins.map(elem => ({ ...elem, role: 'admin' })),
      ...state.dbCollaborators.map(elem => ({ ...elem, role: 'collaborator' })),
    ])],
    admins: state => state.dbAdmins.map(elem => ({ ...elem, role: 'admin' })),
    laligaAdmins: state => state.dblaligaAdmins.map(elem => ({ ...elem, role: 'laligaAdmin' })),
    collaborators: state => state.dbCollaborators.map(elem => ({ ...elem, role: 'collaborator' })),
    data: state => state.dbData,
    sport: state => state.dbSport,
    terms: state => state.dbTerms,
    userRole: (state, getters) => getOrganizationCurrentRoleFromBooleans(getters.isUserAdmin, getters.isUserCollaborator, getters.islaligaAdmin),
    buys: state => state.dbBuys.filter(p => !p.retryBuyId),
    // TODO: review payments migration
    payments: state => state.dbBuys.filter(b => b.payment.status !== 'requested').map(({ payment, ...rest }) => ({ ...rest, buyId: rest.id, ...payment })),
    coupons: state => state.dbCoupons,
    products: state => state.dbProducts,
    auTrips: state => state.dbAuTrips,
    laligaTeams: state => state.dbLaLigaTeams,
    rawSubscribers: state => state.dbSubscribers,
    rolePresets: state => state.dbRolePresets,
    // second param is org demo
    isTec: state => state.dbData?.id === 'ojDUQtQTb3zloOLxlUlR' || state.dbData?.id === '1rAKCqeV8DAfP7C3pQCc',
    routerGuardShowCoupons: state => state.dbData.sport !== 'guild',
  },
  actions: {
    // Read
    async read(context, organizationId) { return (await db.collection('properties').doc(organizationId).get()).data() },
    bind: firestoreAction(({ bindFirestoreRef }, id) => bindFirestoreRef('dbData', db.collection('properties').doc(id))),
    bindSport: firestoreAction(({ bindFirestoreRef }, { sportId }) => bindFirestoreRef('dbSport', db.collection('sport').doc(sportId))),
    bindSubscribers: firestoreAction(({ bindFirestoreRef }, id) => bindFirestoreRef(
      'dbSubscribers',
      db.collection('users').orderBy(`roles.byProperty.${id}.joinedAt`, 'desc').limit(50),

    )),
    bindEventLog: firestoreAction(({ bindFirestoreRef }, id) => bindFirestoreRef('dbEventLog', db.collection(`properties/${id}/eventLog`).doc('eventLog'))),
    bindClusters: firestoreAction(({ bindFirestoreRef }, organizationId) => bindFirestoreRef('dbClusters', db.collection(`properties/${organizationId}/clusters`))),
    bindProjects: firestoreAction(({ bindFirestoreRef }, id) => bindFirestoreRef('dbProjects', db.collection(`properties/${id}/projects`))),
    bindBenefitRequests: firestoreAction(({ bindFirestoreRef }, { organizationId }) => bindFirestoreRef('dbBenefitRequests', db.collection(`properties/${organizationId}/benefitRequests`))),
    bindBuys: firestoreAction(({ bindFirestoreRef }, { organizationId }) => bindFirestoreRef(
      'dbBuys',
      db.collection('buys').where('organizationId', '==', organizationId),
    )),
    bindAdmins: firestoreAction(({ bindFirestoreRef }, id) => bindFirestoreRef(
      'dbAdmins',
      db.collection('users').where(`roles.byProperty.${id}.roles`, 'array-contains', 'admin'),
    )),
    bindlaligaAdmins: firestoreAction(({ bindFirestoreRef }, id) => bindFirestoreRef(
      'dblaligaAdmins',
      db.collection('users').where(`roles.byProperty.${id}.roles`, 'array-contains', 'laligaAdmin'),
    )),
    bindLaLigaTeams: firestoreAction(({ bindFirestoreRef }, { organizationId }) => bindFirestoreRef('dbLaLigaTeams', db.collection(`properties/${organizationId}/laligaTeams`))),
    bindCollaborators: firestoreAction(({ bindFirestoreRef }, id) => bindFirestoreRef(
      'dbCollaborators',
      db.collection('users').where(`roles.byProperty.${id}.roles`, 'array-contains', 'collaborator'),
    )),
    bindTerms: firestoreAction(({ bindFirestoreRef }, id) => bindFirestoreRef('dbTerms', db.collection(`properties/${id}/terms`))),
    unbindBuys: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('dbBuys')),
    unbindProducts: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('dbProducts')),
    bindCoupons: firestoreAction(({ bindFirestoreRef }, id) => bindFirestoreRef(
      'dbCoupons',
      db.collection('coupons').where('organizationId', '==', id),
    )),
    bindProducts: firestoreAction(({ bindFirestoreRef }, { organizationId }) => bindFirestoreRef(
      'dbProducts',
      db.collection('products').where('organizationId', '==', organizationId),
    )),
    bindAuTrips: firestoreAction(({ bindFirestoreRef }, { organizationId }) => bindFirestoreRef('dbAuTrips', db.collection(`properties/${organizationId}/auTrips`))),
    bindRolePresets: firestoreAction(({ bindFirestoreRef }, { organizationId }) => bindFirestoreRef('dbRolePresets', db.collection(`properties/${organizationId}/rolePresets`))),
    readSubscribers: async ({ state }, { organizationId }) => (await Promise.all(state.dbProjects.map(async project => (await db.collection('users')
      .where(`roles.byProperty.${organizationId}.byProject.${project.id}.roles`, 'array-contains', 'subscriber')
      .get()).docs.map(user => user.data())))).flat(),
    readProjects: async (context, { organizationId }) => (await db.collection(`properties/${organizationId}/projects`).get()).docs.map(project => project.data()),
    async readSport(context, id) { return (await db.collection('sport').doc(id).get()).data() },
    getStaff: ({ rootState }, { organizationId }) => callBackend('exports/organization/get-staff', { organizationId, lang: rootState.locale }),
    getSubscribers: ({ rootState }, { organizationId }) => callBackend('exports/organization/get-subscribers', { organizationId, lang: rootState.locale }),
    sendToProviderBenefitRequest: (context, { organizationId, benefitRequestId }) => callBackend('benefit-requests/send-to-provider', { organizationId, benefitRequestId }),
    // Create
    async create(context, { data }) {
      const organizationRef = db.collection('properties').doc()
      const createdAt = FieldValue.serverTimestamp()
      const organizationId = organizationRef.id
      const app = getOrganizationApp(organizationId)
      const socialMetaTagInfo = {
        socialTitle: `${i18n.t('common.enrollTo')} ${data.name}`,
        socialImageLink: 'https://firebasestorage.googleapis.com/v0/b/owqlo-master-production-id.appspot.com/o/socialImageLink.png?alt=media&token=f4c83503-d786-4c55-88dd-03ea82fe605a',
      }
      const organization = {
        ...data,
        id: organizationId,
        createdAt,
        updatedAt: createdAt,
        access: window.isFutbolistasOn ? 'public' : 'owqlo',
        apps: [window.isFutbolistasOn ? 'futon' : 'owqlo'],
        active: true,
        dynamicLink: await callBackend('dynamic-links/get', { path: 'organization_detail', params: { organizationId }, app, socialMetaTagInfo }),
        socialMetaTagInfo,
      }
      await organizationRef.set(organization)
      await context.dispatch('setOrganizationRolePresets', { organizationId })
    },
    async createClone(context, { organizationId }) {
      const originalOrganization = context.rootState.superadmin.dbOrganizations.find(organization => organization.id === organizationId)
      const newOrganizationRef = db.collection('properties').doc()
      const name = `${originalOrganization.name} - ${i18n.t('common.copy')}`
      const app = getOrganizationApp(organizationId)
      const socialMetaTagInfo = {
        socialTitle: `${i18n.t('common.enrollTo')} ${name}`,
        socialImageLink: 'https://firebasestorage.googleapis.com/v0/b/owqlo-master-production-id.appspot.com/o/socialImageLink.png?alt=media&token=f4c83503-d786-4c55-88dd-03ea82fe605a',
      }
      const organization = {
        ...originalOrganization,
        id: newOrganizationRef.id,
        name,
        dynamicLink: await callBackend('dynamic-links/get', { path: 'organization_detail', params: { organizationId: newOrganizationRef.id }, app, socialMetaTagInfo }),
        socialMetaTagInfo,
        createdAt: FieldValue.serverTimestamp(),
        updatedAt: FieldValue.serverTimestamp(),
      }
      await newOrganizationRef.set(organization)
      await context.dispatch('setOrganizationRolePresets', { organizationId: newOrganizationRef.id })
      return newOrganizationRef.id
    },
    setOrganizationRolePresets(context, { organizationId }) {
      const rolePresets = context.rootGetters['superadmin/rolePresets']
      rolePresets
        .map(preset => db.collection(`properties/${organizationId}/rolePresets`).doc(preset.id)
          .set((({ entityType, ...rest }) => ({ ...rest }))(preset)))
    },
    // Update
    async update({ state }, { organizationId, data }) {
      const storagePath = `properties/${organizationId}`
      const logo = await uploadFileAndGetUrl(storagePath, data.logo)
      const banner = await uploadFileAndGetUrl(storagePath, data.banner)
      const header = await uploadFileAndGetUrl(storagePath, data.header)
      const heroHeader = await uploadFileAndGetUrl(storagePath, data.heroHeader)
      const firebaseData = {
        ...data,
        logo,
        ...(
          logo
          && ({
            logos: [
              {
                id: '',
                name: '',
                publicUrl: logo,
                privateUrl: '',
              },
            ],
          })
        ),
        banner,
        header,
        heroHeader,
        updatedAt: FieldValue.serverTimestamp(),
      }
      // update organization data
      await db.collection('properties').doc(organizationId).set(firebaseData, { merge: true })

      logEvent({ action: 'update', entityType: 'organization', entity: state.dbData })
      return true
    },

    // Privacy Policy
    async updateTerms({ state, getters }, { organizationId, defaultLanguage, termsList }) {
      const firebaseData = {
        terms: {
          languages: termsList.map(term => term.id),
          defaultLanguage,
        },
        updatedAt: FieldValue.serverTimestamp(),
      }
      // update organization
      await db.collection('properties').doc(organizationId).update(firebaseData)

      // Remove terms
      if (getters.terms.length) {
        const termsTodelete = getters.terms.filter(t => !termsList.map(t1 => t1.id).includes(t.id))
        await Promise.all(termsTodelete.map(term => db.collection(`properties/${organizationId}/terms`).doc(term.id).delete()))
      }

      // update terms
      await Promise.all(termsList.map(term => db.collection(`properties/${organizationId}/terms`).doc(term.id).set(term)))
      logEvent({ action: 'update', entityType: 'organization', entity: state.dbData })
      return true
    },

    // Delete
    delete(context, { organizationId }) {
      return callBackend('recursive-delete', { path: `properties/${organizationId}` })
    },

    // Other
    async addStaffer({ state, getters, dispatch, rootState }, { organizationId, email, role }) {
      const entity = { type: 'organization', role, organizationId }
      const user = (await db.collection('users').where('email', '==', email).get()).docs[0]?.data()
      const lang = rootState.locale
      if (user) {
        const isAlreadyStaffer = getters.members.map(member => member.id).includes(user.id)
        if (isAlreadyStaffer) {
          const staffer = getters.members.find(s => s.id === user.id)
          if (staffer.role === role) return false
          await dispatch('removeStaffer', { organizationId, user: staffer, role })
        }
        await subscribeToEntity({ userId: user.id, entity, lang, isStaff: true })
      } else {
        const secretKey = Math.random().toString(36).substring(2, 15)
        const invitationRef = db.collection('invitations').doc(email)
        await invitationRef.set({ entitiesToSubscribe: [entity], id: email, secretKey, createdAt: FieldValue.serverTimestamp() })
        callBackend('emails/subscribe', {
          to: email,
          lang,
          params: {
            role,
            platform: 'web',
            organizationId: state.dbData.id,
            webLink: await getEmailLoginLink({ isNewUser: true, email, secretKey, app: getOrganizationApp(organizationId) }),
            isNewUser: true,
          },
        })
      }
      const targetUser = user ? { ...user, role } : { id: null, firstName: email, lastName: '', role }
      logEvent({ action: 'addUser', entityType: 'organization', entity: state.dbData, targetUser })
      return true
    },
    async removeStaffer({ state }, { organizationId, user, role }) {
      const entity = { type: 'organization', role, organizationId }
      await unsubscribeFromEntity({ userId: user.id, entity })
      logEvent({ action: 'removeUser', entityType: 'organization', entity: state.dbData, targetUser: user })
      return true
    },
  },
}
