export const light = {
  primary: '#2acccc',
  secondary: '#404040',
  accent: '#82B1FF',
  error: '#e02020',
  info: '#2196F3',
  success: '#4CAF50',
  warning: '#FFC107',
  warningDark: '#C84F00',
  inactive: '#F5F5F5',
  archived: '#000000',
  unpublished: '#ff8f45',
  pending: '#f8c125',
  upToDate: '#89d184',
  yellow10: '#FEF9E9',
  yellow20: '#FEF3D3',
  yellow70: '#FAD466',
  yellow80: '#F8C125',
  yellow90: '#E3C044',
  grey05: '#F6F6F6',
  grey30: '#D8D8D8',
  grey40: '#C1C1C1',
  grey50: '#979797',
  grey70: '#6C6C6C',
  green10: '#F3FAF3',
  green70: '#ACDFA9',
  green80: '#89D184',
  green90: '#64BD82',
  red10: '#FCEEEE',
  red20: '#F9DDDD',
  red70: '#E98888',
  red80: '#DF5555',
  acqua10: '#E1FFFF',
  acqua50: '#2ACCCC',
  acqua70: '#069595',
  orange10: '#FFF0E7',
  orange40: '#FFAA73',
  orange50: '#FF8F45',
  orange70: '#E46919',
  orange80: '#C84F00',
  orange90: '#D67F4E',
  green100: '#5E8A98',
  blue10: '#5EB0D2',
  purple10: '#81669F',
  purple20: '#E35AC5',
  black: '#000000',
  white: '#FFFFFF',
}
