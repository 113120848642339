import { firestoreAction } from 'vuexfire'
import { db, callBackend, FieldValue, uploadFileAndGetObj } from '@/services/firebase'
import getUserEntityIds from '@/utils/getUserEntityIds'
import rolesEnum from '@/enums/rolesEnum'

export default {
  namespaced: true,
  state: () => ({
    dbSubscriber: null,
    dbParent: null,
    dbChildren: [],
    dbMonitorings: [],
    dbMedicalIncidents: [],
    dbMedicalEvaluations: [],
    dbAcademicEvaluations: [],
  }),
  getters: {
    subscriber: (state, getters, rootState, rootGetters) => ({
      ...state.dbSubscriber,
      teamId: getUserEntityIds(
        state.dbSubscriber?.roles?.byProperty?.[rootGetters['organization/data'].id]?.byProject[rootGetters['project/data'].id],
        'team',
        rolesEnum.PLAYER,
      )[0],
    }),
    parent: state => state.dbParent,
    monitorings: state => state.dbMonitorings,
    rawMedicalIncidents: state => state.dbMedicalIncidents,
    rawMedicalEvaluations: state => state.dbMedicalEvaluations,
    rawAcademicEvaluations: state => state.dbAcademicEvaluations,
  },
  actions: {
    async getLastDayMonitoring(context, { organizationId, projectId, subscriberId }) {
      const monitoring = (await db.collection(`users/${subscriberId}/monitoring`).where('organizationId', '==', organizationId).where('projectId', '==', projectId).orderBy('date', 'desc').limit(1).get()).docs[0]?.data()
      return monitoring ?? null
    },
    // Binds
    bindSubscriber: firestoreAction(({ bindFirestoreRef }, userId) => bindFirestoreRef(
      'dbSubscriber',
      db.collection('users').doc(userId),
    )),
    bindParent: firestoreAction(({ bindFirestoreRef }, { parentId }) => bindFirestoreRef(
      'dbParent',
      db.collection('users').doc(parentId),
    )),
    bindMonitorings: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId, subscriberId }) => bindFirestoreRef(
      'dbMonitorings',
      db.collection(`users/${subscriberId}/monitoring`).where('organizationId', '==', organizationId).where('projectId', '==', projectId),
    )),
    bindMedicalIncidents: firestoreAction(({ bindFirestoreRef }, { projectId, subscriberId }) => bindFirestoreRef(
      'dbMedicalIncidents',
      db.collection(`users/${subscriberId}/membership/subscriber-project-${projectId}/medicalIncidents`),
    )),
    bindMedicalEvaluations: firestoreAction(({ bindFirestoreRef }, { projectId, subscriberId }) => bindFirestoreRef(
      'dbMedicalEvaluations',
      db.collection(`users/${subscriberId}/membership/subscriber-project-${projectId}/medicalEvaluations`),
    )),
    bindAcademicEvaluations: firestoreAction(({ bindFirestoreRef }, { projectId, subscriberId }) => bindFirestoreRef(
      'dbAcademicEvaluations',
      db.collection(`users/${subscriberId}/membership/subscriber-project-${projectId}/academicEvaluations`),
    )),
    unbindMedicalIncidents: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('dbMedicalIncidents')),
    unbindMedicalEvaluations: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('dbMedicalEvaluations')),
    unbindAcademicEvaluations: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('dbAcademicEvaluations')),

    // Create
    async createMedicalIncident(context, { subscriberId, projectId, data }) {
      const medicalIncidentRef = db.collection(`users/${subscriberId}/membership/subscriber-project-${projectId}/medicalIncidents`).doc()
      const dataToSave = { id: medicalIncidentRef.id, ...data, createdAt: FieldValue.serverTimestamp(), updatedAt: FieldValue.serverTimestamp() }
      await medicalIncidentRef.set(dataToSave)
    },
    async createMedicalEvaluation(context, { subscriberId, projectId, data }) {
      const medicalEvaluationRef = db.collection(`users/${subscriberId}/membership/subscriber-project-${projectId}/medicalEvaluations`).doc()
      const storagePath = `users/${subscriberId}/membership/subscriber-project-${projectId}`

      const dataWithFilesUploaded = await context.dispatch('uploadFilesMedicalEvaluation', { storagePath, data })
      const createdAt = FieldValue.serverTimestamp()
      const dataToSave = { id: medicalEvaluationRef.id, ...dataWithFilesUploaded, createdAt, updatedAt: createdAt }
      await medicalEvaluationRef.set(dataToSave)
    },
    async createAcademicEvaluation(context, { subscriberId, projectId, data }) {
      const academicEvaluationRef = db.collection(`users/${subscriberId}/membership/subscriber-project-${projectId}/academicEvaluations`).doc()
      const dataToSave = { id: academicEvaluationRef.id, ...data, createdAt: FieldValue.serverTimestamp(), updatedAt: FieldValue.serverTimestamp() }
      await academicEvaluationRef.set(dataToSave)
    },

    // Update
    async updateMedicalIncident(context, { subscriberId, projectId, medicalIncidentId, data }) {
      const dataToUpdate = { ...data, updatedAt: FieldValue.serverTimestamp() }
      await db.collection(`users/${subscriberId}/membership/subscriber-project-${projectId}/medicalIncidents`).doc(medicalIncidentId).update(dataToUpdate)
    },
    async updateMedicalEvaluation(context, { subscriberId, projectId, medicalEvaluationId, data }) {
      const storagePath = `users/${subscriberId}/membership/subscriber-project-${projectId}`

      const dataWithFilesUploaded = await context.dispatch('uploadFilesMedicalEvaluation', { storagePath, data })
      const dataToUpdate = { ...dataWithFilesUploaded, updatedAt: FieldValue.serverTimestamp() }
      await db.collection(`users/${subscriberId}/membership/subscriber-project-${projectId}/medicalEvaluations`).doc(medicalEvaluationId).update(dataToUpdate)
    },

    async updateAcademicEvaluation(context, { subscriberId, projectId, academicEvaluationId, data }) {
      const dataToUpdate = { ...data, updatedAt: FieldValue.serverTimestamp() }
      await db.collection(`users/${subscriberId}/membership/subscriber-project-${projectId}/academicEvaluations`).doc(academicEvaluationId).update(dataToUpdate)
    },
    async updateNeeds(context, { subscriberId, projectId, data }) {
      const dataToUpdate = { ...data, updatedAt: FieldValue.serverTimestamp() }
      await db.doc(`users/${subscriberId}/membership/subscriber-project-${projectId}`).update(dataToUpdate)
    },

    async monitoringOverwriteLoadEvaluation(context, { subscriberId, monitoringId, loadEvaluationOverwrite }) {
      const dataToUpdate = { loadEvaluationOverwrite, updatedAt: FieldValue.serverTimestamp() }
      await db.collection(`users/${subscriberId}/monitoring`).doc(monitoringId).update(dataToUpdate)
    },

    async monitoringOverwriteRecoveryEvaluation(context, { subscriberId, monitoringId, recoveryEvaluationOverwrite }) {
      const dataToUpdate = { 'adaptationsResponses.recoveryEvaluationOverwrite': recoveryEvaluationOverwrite, 'updatedAt': FieldValue.serverTimestamp() }
      await db.collection(`users/${subscriberId}/monitoring`).doc(monitoringId).update(dataToUpdate)
    },

    // Delete
    removeSubscriber: async (context, { organizationId, projectId, userId, role }) => {
      await callBackend('projects/users/unsubscribe', { organizationId, projectId, userId, role })
      const membership = await context.dispatch(
        'user/readMembershipInfo',
        { id: userId, role: 'subscriber', entityType: 'project', entityId: projectId },
        { root: true },
      )
      if (membership.buyId) {
        const buy = await context.dispatch('project/readBuy', (membership.buyId), { root: true })
        const payer = await context.dispatch('user/read', buy.userId, { root: true })
        await context.dispatch('cancelSubscription, '({ subscriptionId: buy.subscriptionId, payerEmail: payer?.email, payerId: payer?.id, paymentName: buy.name }))
      }
    },
    async deleteMedicalIncident(context, { subscriberId, projectId, medicalIncidentId }) {
      await db.collection(`users/${subscriberId}/membership/subscriber-project-${projectId}/medicalIncidents`).doc(medicalIncidentId).delete()
    },
    async deleteMedicalEvaluation(context, { subscriberId, projectId, medicalEvaluationId }) {
      await db.collection(`users/${subscriberId}/membership/subscriber-project-${projectId}/medicalEvaluations`).doc(medicalEvaluationId).delete()
    },
    async uploadFilesMedicalEvaluation(context, { storagePath, data }) {
      if (data.posture?.frontalStanding) data.posture.frontalStanding = [await uploadFileAndGetObj(storagePath, data.posture?.frontalStanding[0])]
      if (data.posture?.transverseStanding) data.posture.transverseStanding = [await uploadFileAndGetObj(storagePath, data.posture?.transverseStanding[0])]
      if (data.posture?.trunkFlexion) data.posture.trunkFlexion = [await uploadFileAndGetObj(storagePath, data.posture?.trunkFlexion[0])]
      if (data.posture?.trunkExtension) data.posture.trunkExtension = [await uploadFileAndGetObj(storagePath, data.posture?.trunkExtension[0])]
      if (data.posture?.inclinations) data.posture.inclinations = [await uploadFileAndGetObj(storagePath, data.posture?.inclinations[0])]
      return data
    },
  },
}
